.card-event .card-image {
  transition: transform 0.3s ease-in-out, box-shadow 0.1s ease;
}

.card-event:hover .card-image {
  transform: scale(1.1); /* Rotate and slightly scale the image */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add shadow on hover for depth */
}


.slick-menu-compagny .slick-arrow {
  background-color: rgb(155, 155, 155);
  border-radius: 50%;
}

